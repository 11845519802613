import React from 'react';
import styled from '@emotion/styled';

import { HeaderText } from './text';
import { colour } from '../constants/style';
import { ExternalButton } from './Button';
import { ReactComponent as Canister } from '../assets/icons/storage-canister.svg';
import { ReactComponent as Assassin } from '../assets/icons/assassin.svg';
import { ReactComponent as WorldGen } from '../assets/icons/world-generator.svg';
import { ReactComponent as Itch } from '../assets/icons/itchio-textless-black.svg';
import { ReactComponent as Die } from '../assets/icons/die.svg';


const WelcomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DieIconCharTraits = styled(Die)`
  .die {
    fill: ${colour.traitBody};
  }
  .pip {
    fill: ${colour.traitPips};
  }
`;

const DieIconShadowdark = styled(Die)`
  .die {
    fill: ${colour.shadowdarkBody};
  }
  .pip {
    fill: ${colour.shadowdarkPips};
  }
`;

const generalGameResources = [
  {
    to: 'https://world-generator.tarek-embree.com/',
    label: 'Random World Generator',
    icon: (
      <WorldGen
        width={50}
      />
    ),
  },
  {
    to: 'https://mtg.tarek-embree.com/',
    label: 'Random Magic Booster Pack',
  },
  {
    to: 'https://traits.tarek-embree.com/',
    label: 'Random Character Traits',
    icon: (
      <DieIconCharTraits
        width={50}
      />
    ),
  },
];

const specificGameResources = [
  {
    to: 'https://assassin-tools.tarek-embree.com/',
    label: 'Assassin\'s Creed Dice Roller',
    icon: (
      <Assassin
        width={50}
        fill={colour.assassin}
      />
    ),
  },
  {
    to: 'https://commodity.tarek-embree.com/',
    label: 'Frontier Space Commodity Converter',
    icon: (
      <Canister
        width={50}
        stroke={colour.canister}
      />
    ),
  },
  {
    to: 'https://boards.tarek-embree.com/',
    label: 'Frontier Space Community Boards',
  },
  {
    to: 'https://west.tarek-embree.com/',
    label: 'Grey Sea Weapon Customiser (More Tools to Come)',
  },
  {
    to: 'https://shadowdark.tarek-embree.com/',
    label: 'Shadowdark Randomisers',
    icon: (
      <DieIconShadowdark
        width={50}
      />
    ),
  },
];

const otherProjects = [
  {
    to: 'https://expenses.tarek-embree.com/',
    label: 'Expense Tracker',
  },
  {
    to: 'https://meeinotl.itch.io/',
    label: 'Itch.io',
    icon: (
      <Itch
        width={50}
        fill={colour.itch}
      />
    ),
  },
];

const ExternalLinks = ({ links }) => (
  links.map(link => (
    <ExternalButton
      to={link.to}
      label={link.label}
      icon={link.icon}
      key={link.to}
    />
  )));

export const Welcome = () => (
  <WelcomeWrapper>
    <HeaderText>General RPG Resources</HeaderText>
    <ExternalLinks links={generalGameResources} />
    <HeaderText>Resources for Specific RPG&apos;s</HeaderText>
    <ExternalLinks links={specificGameResources} />
    <HeaderText>Other Projects</HeaderText>
    <ExternalLinks links={otherProjects} />
  </WelcomeWrapper>
);
