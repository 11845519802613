import { createMuiTheme } from '@material-ui/core/styles';
import { css } from '@emotion/core';


export const colour = {
  primary: '#035',
  secondary: '#624a2e',
  highlight: '#f2f6fc',
  background: '#9ab7e3',
  assassin: '#d20000',
  canister: '#B4ECB4',
  itch: '#DDD',
  traitBody: '#624a2e',
  traitPips: '#f2f6fc',
  shadowdarkBody: '#FFF',
  shadowdarkPips: '#111',
};

export const fontSize = {
  pageTitle: '32px',
  header: '20px',
  body: '16px',
};

const borderRadius = 8;

export const roundCorner = {
  all: `border-radius: ${borderRadius}px`,
  right: `border-radius: 0 ${borderRadius}px ${borderRadius}px 0`,
  left: `border-radius: ${borderRadius}px 0 0 ${borderRadius}px`,
  top: `border-radius: ${borderRadius}px ${borderRadius}px 0 0`,
  bottom: `border-radius: 0 0 ${borderRadius}px ${borderRadius}px`,
};

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: colour.primary,
      contrastText: colour.highlight,
    },
    secondary: {
      main: colour.secondary,
      contrastText: colour.highlight,
    },
  },
  typography: {
    fontFamily: [
      'Nova Mono',
      'monospace',
    ].join(','),
    fontSize: 16,
  },
});

export const globalStyles = css`
  body {
    background-color: ${colour.highlight};
  }
`;
